import type { Ref } from 'vue'
import {
  buildEventsByEntityMap,
  type EventsByEntityType,
} from 'markets-store/helpers/filterCustomMenu'
import { filterMap } from '@st/utils'
import type {
  CustomMenuItem,
  CustomMenuClass,
  TSportEvent,
} from 'markets-store/types'
import { CYBER_SPORT_ID } from 'markets-store/constants'
import { getMenuItemIcon } from 'markets-store/helpers/getMenuItemIcon'
import { useMarketsStore } from './useMarketsStore'
import type { ExtendedMenuItem } from '../types'

interface UseSportMenuReturn {
  menu: Readonly<Ref<ExtendedMenuItem[]>>
  liveEventsMap: Readonly<Ref<EventsByEntityType>>
}

function getLink(item: CustomMenuItem) {
  switch (item.type) {
    case 'sport':
      return `/sport/${item.id}`
    case 'category':
      return `/sport/${item.sportId}/${item.id}`
    case 'tournament':
      return `/sport/${item.sportId}/${item.categoryId}/${item.id}`
    default:
      return `/sport/${item.id}`
  }
}

interface FilterAndFormatMenuParams {
  menu: CustomMenuItem[]
  eventsMap: EventsByEntityType
  liveEventsMap: EventsByEntityType
  level?: number
  /**
   * Обычно ветки дерева, в которых нет событий должны удаляться.
   * Но при частичной загрузке актула для главной, эта проверка пропускается
   */
  skipEventsCheck?: boolean
}

function filterAndFormatMenu({
  menu,
  eventsMap,
  liveEventsMap,
  level = 0,
  skipEventsCheck = false,
}: FilterAndFormatMenuParams): ExtendedMenuItem[] {
  return filterMap(
    menu,
    (item) =>
      (skipEventsCheck || eventsMap[item.entityId]?.size > 0) &&
      item.entityId !== `sport-${CYBER_SPORT_ID}`,
    (item) => ({
      ...item,
      children: item.children
        ? filterAndFormatMenu({
            menu: item.children,
            eventsMap,
            liveEventsMap,
            level: level + 1,
            skipEventsCheck,
          })
        : undefined,
      liveSportEventsNumber: liveEventsMap[item.entityId]?.size ?? 0,
      icon: getMenuItemIcon({ item, level })?.name,
      link: item.link?.includes('custom') ? item.link : getLink(item),
      mobileLink: item.link?.includes('custom')
        ? `/mobile${item.link}`
        : `/mobile${getLink(item)}`,
    }),
  )
}

export function useSportMenu(): UseSportMenuReturn {
  const MS = useMarketsStore()

  const customMenu = ref(MS.customMenu) as Ref<CustomMenuClass>
  const events = ref(MS.sportEvents)
  // @ts-expect-error indexedActual is magic
  const liveEvents = ref<TSportEvent[]>(MS.sportEvents.live)
  const actualStates = ref(MS.systemStates)
  const isActualFullyLoaded = computed<boolean>(
    () => actualStates.value.fullActualLoaded,
  )

  const eventsMap = computed(() =>
    buildEventsByEntityMap(customMenu.value, events.value),
  )
  const liveEventsMap = computed(() =>
    buildEventsByEntityMap(customMenu.value, liveEvents.value),
  )

  const menu = computed(() =>
    filterAndFormatMenu({
      menu: customMenu.value.menu,
      eventsMap: eventsMap.value,
      liveEventsMap: liveEventsMap.value,
      skipEventsCheck: !isActualFullyLoaded.value,
    }),
  )

  return { menu, liveEventsMap }
}
