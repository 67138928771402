/**
 * More efficient way to do .filter().map()
 *
 * @example
 * filterMap(
 *   array,
 *   item => item > 3,
 *   item => item * 2
 * )
 * // does the same thing as
 * array
 *   .filter(item => item > 3)
 *   .map(item => item * 2)
 * // but 2x faster
 */

export function filterMap<T, K>(
  array: T[],
  filterFunction: (value: T, index?: number, array?: T[]) => boolean,
  mapFunction: (value: T, index?: number, array?: T[]) => K,
): K[] {
  return array.reduce((accumulator, item, index) => {
    if (filterFunction(item, index, array)) {
      accumulator.push(mapFunction(item, index, array))
    }
    return accumulator
  }, [] as K[])
}
